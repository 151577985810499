/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://wdptqr7i6rd2jez24j2tjrqxim.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2mwqcz7owfbi5i6l5qthmbovv4",
    "aws_cognito_identity_pool_id": "us-east-1:87c39314-0087-406e-84c6-47b27580bfe7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_p0rryhPCL",
    "aws_user_pools_web_client_id": "ls4t83lvk37qtaie0v8hnlhkl",
    "oauth": {}
};


export default awsmobile;
