// import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, Component } from 'react';

import Amplify , { API, graphqlOperation } from 'aws-amplify';
// import Amplify from 'aws-amplify';
import awsExports from './aws-exports';

import './App.css'
import awsconfig from './aws-exports'
import { AmplifySignOut, withAuthenticator} from '@aws-amplify/ui-react'

import Iframe from 'react-iframe';
Amplify.configure(awsExports);
Amplify.configure(awsconfig)

function App() {

  return (
    <div className="App">
      <header className="App-header">
       
      <Iframe url="https://share.clickup.com/g/h/a0vvy-119/073b52aa64530b3"
        width= "100%"
        height="700"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>

        <AmplifySignOut/>
      </header>
    </div>
  );
}

export default withAuthenticator( App );
